import { CategoryApi } from '../../apis/category-api';
import { Category } from '../../models/category';
import { HttpClient } from '../http-client';

export class BackendCategoryApi implements CategoryApi {
  public async fetchAll(): Promise<Category[]> {
    return HttpClient.instance
      .get('categories')
      .json<FetchAllResponse>()
      .then(categories =>
        categories.map(category => ({
          id: category.id,
          title: category.title,
          maxPersons: category.maxParticipants
        }))
      );
  }
}

type FetchAllResponse = BackendCategory[];

type BackendCategory = {
  id: string;
  title: string;
  maxParticipants: number;
};
