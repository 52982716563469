import { SubmitHandler, useForm } from 'react-hook-form';
import { pipe } from '../../utils/pipe';
import { setHours, setMinutes, setSeconds } from 'date-fns/fp';
import { CreateEventCommand, createEvent } from './create-event';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';

export type CreateEventFormValues = {
  categoryId: string | null;
  place: string;
  maxPersons: number;
  day: Date;
  startHour: string;
  endHour: string;
  description: string;
};

export const useCreateEventForm = () => {
  const dispatch = useDispatch<AppDispatch>();

  const form = useForm<CreateEventFormValues>({
    defaultValues: { categoryId: null, place: '35 rue Saint Roch, 75001 Paris' }
  });

  const onSubmit: SubmitHandler<CreateEventFormValues> = async data => {
    const getDate = (time: string): Date => {
      const [hours, minutes] = time.split(':');
      return pipe(new Date(data.day), setHours(+hours), setMinutes(+minutes), setSeconds(0));
    };

    const command: CreateEventCommand = {
      categoryId: data.categoryId!,
      description: data.description,
      maxPersons: data.maxPersons,
      place: data.place,
      start: getDate(data.startHour),
      end: getDate(data.endHour)
    };
    await dispatch(createEvent(command));
  };

  return { form, onSubmit };
};
