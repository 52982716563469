import jwtDecode from 'jwt-decode';
import { AddCoinsPayload, CreateAccountPayload, UpdateUserPayload, UserApi } from '../../apis/user-api';
import { Coins, CoinWithHistory } from '../../models/coin';
import { DetailedMember } from '../../models/detailed-member';
import { Member } from '../../models/member';
import { User } from '../../models/user';
import { HttpClient } from '../http-client';
import { CategoryType } from '../../models/category-type';
import { HTTPError } from 'ky';
import { LoginError } from '../../models/domain-error';

export class BackendUserApi implements UserApi {
  public async login(email: string, password: string): Promise<User> {
    const payload: LoginPayload = { email, password };

    try {
      const { access_token: jwt } = await HttpClient.instance
        .post('auth/login', { json: payload })
        .json<LoginResponse>();
      const jwtDecoded = jwtDecode<JwtPayload>(jwt);

      return {
        token: jwt,
        isAdmin: jwtDecoded.roles.includes('admin')
      };
    } catch (error) {
      if ((error as HTTPError).response.status === 401) {
        throw new LoginError('E_WRONG_CREDENTIALS');
      }
      throw error;
    }
  }

  public async resetPassword(password: string, token: string): Promise<void> {
    await HttpClient.instance.post('auth/reset-password', { json: { password, token } });
  }

  public async sendResetPasswordEmail(email: string): Promise<void> {
    await HttpClient.instance.post('auth/send-reset-password-email', { json: { email } });
  }

  public async getCoins(): Promise<Coins> {
    return HttpClient.instance
      .get('members/me/coins')
      .json<GetCoinsResponse>()
      .then(coins => ({
        impro: coins.impro.map(coin => ({
          ...coin,
          purchaseDate: new Date(coin.purchaseDate),
          expirationDate: new Date(coin.expirationDate)
        })),
        common: coins.common.map(coin => ({
          ...coin,
          purchaseDate: new Date(coin.purchaseDate),
          expirationDate: new Date(coin.expirationDate)
        }))
      }));
  }

  public async getCoinWithHistory(memberId: string, coinId: string): Promise<CoinWithHistory> {
    const coin = await HttpClient.instance.get(`members/coins/${coinId}`).json<GetCoinWithHistoryResponse>();
    return {
      ...coin,
      purchaseDate: new Date(coin.purchaseDate),
      expirationDate: new Date(coin.expirationDate),
      history: coin.history.map(entry => ({ ...entry, date: new Date(entry.date) }))
    };
  }

  public fetchAll(): Promise<Member[]> {
    return HttpClient.instance.get('members').json<FetchAllResponse>();
  }

  public async fetchOne(id: string): Promise<DetailedMember> {
    const member = await HttpClient.instance.get(`members/${id}`).json<FetchOneResponse>();
    return {
      ...member,
      registrationDate: new Date(member.registrationDate),
      coins: {
        impro: member.coins.impro.map(coin => ({
          ...coin,
          purchaseDate: new Date(coin.purchaseDate),
          expirationDate: new Date(coin.expirationDate)
        })),
        common: member.coins.common.map(coin_1 => ({
          ...coin_1,
          purchaseDate: new Date(coin_1.purchaseDate),
          expirationDate: new Date(coin_1.expirationDate)
        }))
      }
    };
  }

  public async addCoins(payload: AddCoinsPayload): Promise<void> {
    const requestPayload: AddCoinsRequestPayload = {
      name: payload.name,
      categoryType: payload.categoryType,
      expirationDate: payload.expirationDate,
      total: payload.total
    };

    await HttpClient.instance.post(`members/${payload.userId}/coins`, { json: requestPayload });
  }

  public async createAccount(payload: CreateAccountPayload): Promise<void> {
    const requestPayload: CreateAccountRequestPayload = payload;
    await HttpClient.instance.post('auth/create-account', { json: requestPayload });
  }

  public async updateUser(payload: UpdateUserPayload): Promise<void> {
    const requestPayload: UpdateUserRequestPayload = {
      availableCategoryIds: payload.availableCategoryIds
    };
    await HttpClient.instance.put(`members/${payload.userId}`, { json: requestPayload });
  }
}

type LoginPayload = {
  email: string;
  password: string;
};

type LoginResponse = {
  access_token: string;
};

type JwtPayload = {
  userId: string;
  userEmail: string;
  roles: ('admin' | 'member')[];
  availableCategoryIds: string[];
};

type BackendCoins = Record<CategoryType, BackendCoin[]>;
type GetCoinsResponse = BackendCoins;

type BackendCoin = {
  id: string;
  name: string;
  purchaseDate: string;
  expirationDate: string;
  total: number;
  used: number;
  remaining: number;
  hasExpired: boolean;
};

type GetCoinWithHistoryResponse = BackendCoin & {
  history: {
    isCancelation: boolean;
    reason: string;
    date: string;
    eventId: string;
  }[];
};

type MemberBackend = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

type FetchAllResponse = MemberBackend[];

export type FetchOneResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  registrationDate: string;
  coins: BackendCoins;
  availableCategoryIds: string[];
};

export type AddCoinsRequestPayload = {
  categoryType: CategoryType;
  name: string;
  expirationDate: Date;
  total: number;
};

export type CreateAccountRequestPayload = {
  firstName: string;
  lastName: string;
  email: string;
  availableCategoryIds: string[];
};

export type UpdateUserRequestPayload = {
  availableCategoryIds: string[];
};
