import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useContext } from 'react';
import { DependenciesContext } from '../../../dependencies/dependencies-context';
import { Event } from '../../../models/event';

export function useFetchEvents<T>(mapFn: (events: Event[]) => T): UseQueryResult<T>;
export function useFetchEvents(): UseQueryResult<Event[]>;
export function useFetchEvents(mapFn = (events: Event[]) => events) {
  const { eventApi: eventGateway } = useContext(DependenciesContext);
  return useQuery(['events'], async () => {
    const events = await eventGateway.fetchAll();
    return mapFn(events);
  });
}
