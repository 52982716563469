import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store/store';

export type CreateEventCommand = {
  categoryId: string;
  place: string;
  maxPersons: number;
  start: Date;
  end: Date;
  description: string;
};

export const createEvent = createAsyncThunk<string, CreateEventCommand, ThunkConfig>(
  'events/create',
  (command, { extra: { eventApi } }) => eventApi!.create(command)
);
