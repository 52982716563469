import { createAction, createReducer } from '@reduxjs/toolkit';
import { AsyncStatus } from '../models/async-status';
import { createAccount } from '../usecases/create-account/create-account';

export type AccountCreationState = {
  status: AsyncStatus;
};

const initialState: AccountCreationState = {
  status: 'idle'
};

export const resetAccountCreationState = createAction('users/resetAccountCreation');

export const accountCreationReducer = createReducer(initialState, builder => {
  builder.addCase(resetAccountCreationState, () => initialState);
  builder.addCase(createAccount.pending, state => ({ ...state, status: 'pending' }));
  builder.addCase(createAccount.rejected, state => ({ ...state, status: 'failed' }));
  builder.addCase(createAccount.fulfilled, state => ({ ...state, status: 'succeeded' }));
});
