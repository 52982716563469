import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetAccountCreationState } from '../../../../core/store/account-creation.state';
import { accountCreationStatusSelector } from '../../../../core/store/selectors/account-creation-status.selector';
import { AppDispatch } from '../../../../core/store/store';
import { CreateAccountCommand, createAccount } from '../../../../core/usecases/create-account/create-account';
import { useToastStatus } from '../../../hooks/use-toast-status';
import { AvailableCategories } from '../../components/AvailableCategories';
import { Display } from '../../components/Display';
import { Loader } from '../../components/Loader';

type CreateMemberFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  categories: Record<string, boolean>;
};

export const CreateMember: FC = () => {
  const { handleSubmit, register } = useForm<CreateMemberFormValues>();
  const { isLoading } = useSelector(accountCreationStatusSelector);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useToastStatus(accountCreationStatusSelector, {
    success: 'Le compte a bien été créé',
    onSuccess: () => navigate('..', { relative: 'path' }),
    finally: () => dispatch(resetAccountCreationState())
  });

  const onSubmit: SubmitHandler<CreateMemberFormValues> = async data => {
    const command: CreateAccountCommand = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      availableCategoryIds: Object.entries(data.categories)
        .filter(([categoryId, checked]) => checked)
        .map(([categoryId, checked]) => categoryId)
    };
    dispatch(createAccount(command));
  };

  return (
    <div>
      <h1 className="text-4xl underline mb-10 decoration-primary">Nouvel adhérent</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="h-stack-wrap gap-8 justify-evenly mb-8">
          <div>
            <h2 className="text-2xl font-bold mb-4">Infos générales</h2>
            <div className="v-stack gap-4">
              <div className="h-stack gap-2 justify-between">
                <label className="text-lg">Prénom :</label>
                <input {...register('firstName')} required className="input w-64" placeholder="Eric" type="text" />
              </div>

              <div className="h-stack gap-2 justify-between">
                <label className="text-lg">Nom :</label>
                <input {...register('lastName')} required className="input w-64" placeholder="Fabre" type="text" />
              </div>

              <div className="h-stack gap-2 justify-between">
                <label className="text-lg">Email :</label>
                <input
                  {...register('email')}
                  required
                  className="input w-64"
                  placeholder="example@mail.fr"
                  type="email"
                />
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-2xl font-bold mb-4">Ateliers accessibles</h2>
            <AvailableCategories register={register} />
          </div>
        </div>

        <div className="h-stack justify-center">
          <Display condition={isLoading}>
            <Loader />
          </Display>
          <Display condition={!isLoading}>
            <button className="btn text-xl px-8 py-2 leading-none" type="submit">
              Créer
            </button>
          </Display>
        </div>
      </form>
    </div>
  );
};
