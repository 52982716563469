import { createSelector } from 'reselect';
import { AppState, AppStore } from '../store/store';
import { TokenManager } from './token-manager';

export const listenTokenChanges = (store: AppStore) => {
  const tokenSelector = createSelector(
    (state: AppState) => state.userState.user?.token,
    token => token
  );

  const setToken = () => {
    const state = store.getState();
    const token = tokenSelector(state);
    TokenManager.setToken(token ?? null);
  };

  setToken();

  store.subscribe(setToken);
};
