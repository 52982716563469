import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { BackendCategoryApi } from './core/adapters/category-api/backend-category-api';
import { DexieCategoryApi } from './core/adapters/category-api/dexie-category-api';
import { BackendEventApi } from './core/adapters/event-api/backend-event-api';
import { DexieEventApi } from './core/adapters/event-api/dexie-event-api';
import { HttpClient } from './core/adapters/http-client';
import { BackendUserApi } from './core/adapters/user-api/backend-user-api';
import { DexieUserApi } from './core/adapters/user-api/dexie-user-api';
import { Dependencies } from './core/dependencies/dependencies';
import { DependenciesContext } from './core/dependencies/dependencies-context';
import { createDatabase } from './core/storage/db';
import { AppStore, createStoreWithLocalStorageSync } from './core/store/store';
import { logout } from './core/usecases/logout/logout';
import './index.css';
import { routes } from './react/router';
import reportWebVitals from './reportWebVitals';

function getDependencies(): Dependencies {
  if (process.env.REACT_APP_LOCAL === 'true') {
    const dexieDb = createDatabase();
    return {
      eventApi: new DexieEventApi(dexieDb),
      categoryApi: new DexieCategoryApi(dexieDb),
      userApi: new DexieUserApi(dexieDb)
    };
  }
  return {
    eventApi: new BackendEventApi(),
    userApi: new BackendUserApi(),
    categoryApi: new BackendCategoryApi()
  };
}

const dependencies = getDependencies();

const store = createStoreWithLocalStorageSync(dependencies, ['userState']);
listenUnauthorizedRequest(store);

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter(createRoutesFromElements(routes));

root.render(
  <React.StrictMode>
    <DependenciesContext.Provider value={dependencies}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <RouterProvider router={router}></RouterProvider>
        </Provider>
      </QueryClientProvider>
    </DependenciesContext.Provider>
  </React.StrictMode>
);

function listenUnauthorizedRequest(store: AppStore) {
  HttpClient.onUnauthorizedRequest(() => {
    store.dispatch(logout());
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
