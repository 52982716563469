import { useSelector } from 'react-redux';
import { asyncStatusSelector } from '../../core/store/selectors/async-status.selector';
import { useEffect } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { AppState } from '../../core/store/store';

interface Options {
  success: string;
  error?: string;
  onFail?: () => any | Promise<any>;
  onSuccess?: () => any | Promise<any>;
  finally?: () => any | Promise<any>;
  toastOptions?: ToastOptions;
}

export function useToastStatus(
  selector: (state: AppState) => ReturnType<typeof asyncStatusSelector>,
  options: Options
) {
  const { hasFailed, hasSucceeded } = useSelector(selector);

  useEffect(() => {
    if (hasFailed) {
      toast.error(options.error ?? 'Une erreur est survenue. Veuillez réessayez.', options.toastOptions);
      options?.onFail?.();
      options?.finally?.();
    }
  }, [hasFailed, options]);

  useEffect(() => {
    if (hasSucceeded) {
      toast.success(options.success, options.toastOptions);
      options?.onSuccess?.();
      options?.finally?.();
    }
  }, [hasSucceeded, options]);
}
