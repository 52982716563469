import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { loginStatusSelector } from '../../../../../core/store/selectors/login-status.selector';
import { Display } from '../../../components/Display';
import { Loader } from '../../../components/Loader';
import { useLogin } from './useLogin';

type LoginFormValues = {
  email: string;
  password: string;
};

export const Login: FC = () => {
  const { isLoading, hasFailed, errorCode } = useSelector(loginStatusSelector);
  const { register, handleSubmit } = useForm<LoginFormValues>();
  const login = useLogin();
  const onSubmit: SubmitHandler<LoginFormValues> = login;

  return (
    <div className="w-full h-stack justify-center">
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <div>
            <img
              className="mx-auto h-24 w-auto rounded-full p-2 box-content bg-secondary"
              src={process.env.PUBLIC_URL + '/logo.png'}
              alt="Logo À Petits Pas"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Connexion</h2>
          </div>
          <form className="mt-8 min-w-[300px] v-stack gap-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <input
                  id="email"
                  type="email"
                  required
                  autoComplete="email"
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-ternary focus:outline-none focus:ring-ternary sm:text-sm transition duration-150 ease-in-out"
                  placeholder="Adresse email"
                  {...register('email')}
                />
              </div>
              <div>
                <input
                  id="password"
                  type="password"
                  required
                  autoComplete="current-password"
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-ternary focus:outline-none focus:ring-ternary sm:text-sm transition duration-150 ease-in-out"
                  placeholder="Mot de passe"
                  {...register('password')}
                />
              </div>
            </div>

            <div className="ml-1 w-full h-stack justify-end">
              <Link to="/mot-de-passe-oublie" className="text-secondary hover:underline sm:text-text-sm cursor-pointer">
                Mot de passe oublié ?
              </Link>
            </div>

            <Display condition={isLoading}>
              <div className="text-center">
                <Loader />
              </div>
            </Display>

            <Display condition={hasFailed}>
              <div className="border-2 border-primary rounded-md p-2 text-center">
                <Display condition={errorCode === 'E_WRONG_CREDENTIALS'}>
                  L'email ou le mot de passe est invalide
                </Display>
                <Display condition={errorCode === null}>Une erreur est survenue. Veuillez réessayer.</Display>
              </div>
            </Display>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="flex w-full justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white hover:bg-ternary hover:text-black focus:outline-none transition duration-150 ease-in-out disabled:bg-gray-500 disabled:text-white"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                Se connecter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
