import { FC } from 'react';
import { useCoinsAmounts } from '../../../core/usecases/queries/coins/useCoinsAmounts';
import { CommonCoin, ImproCoin } from '../components/Coin';
import { Display } from '../components/Display';

export const TopBarCoins: FC = () => {
  const { data: coins } = useCoinsAmounts();

  return (
    <>
      <Display condition={coins?.impro !== undefined}>
        <div className="h-stack gap-1 items-center">
          <ImproCoin />
          <span className="text-xl">{coins?.impro}</span>
        </div>
      </Display>
      <Display condition={coins?.common !== undefined}>
        <div className="h-stack gap-1 items-center">
          <CommonCoin />
          <span className="text-xl">{coins?.common}</span>
        </div>
      </Display>
    </>
  );
};
