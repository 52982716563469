import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useContext } from 'react';
import { DependenciesContext } from '../../../dependencies/dependencies-context';
import { DetailedEvent } from '../../../models/detailed-event';

export function useFetchEvent<T>(id: string, mapper: (event: DetailedEvent) => T): UseQueryResult<T>;
export function useFetchEvent(id: string): UseQueryResult<DetailedEvent>;
export function useFetchEvent(id: string, mapper: (event: DetailedEvent) => any = event => event): UseQueryResult<any> {
  const { eventApi: eventGateway } = useContext(DependenciesContext);
  return useQuery(['event'], async () => {
    const result = await eventGateway.fetchOne(id);
    return mapper(result);
  });
}
