import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { resetEventCreationState } from '../../../../core/store/event-creation.state';
import { eventCreationStatusSelector } from '../../../../core/store/selectors/event-creation-status.selector';
import { AppDispatch } from '../../../../core/store/store';
import { useCategories } from '../../../../core/usecases/create-event/useCategories';
import { Display } from '../../components/Display';
import { Loader } from '../../components/Loader';
import { Select } from '../../components/Select';
import { useToastStatus } from '../../../hooks/use-toast-status';
import { useCreateEventForm } from '../../../../core/usecases/create-event/useCreateEventForm';

export const CreateEvent: FC = () => {
  const navigate = useNavigate();
  const { options, categories } = useCategories();
  const { isLoading, hasSucceeded } = useSelector(eventCreationStatusSelector);
  const dispatch = useDispatch<AppDispatch>();
  const {
    form: { handleSubmit, getValues, setValue, register },
    onSubmit
  } = useCreateEventForm();

  useToastStatus(eventCreationStatusSelector, { success: "L'atelier a bien été créé" });

  useEffect(() => {
    return () => {
      dispatch(resetEventCreationState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (hasSucceeded) {
      navigate('..', { relative: 'path' });
    }
  }, [hasSucceeded, navigate]);

  return (
    <div>
      <h1 className="text-4xl underline mb-10 decoration-primary">Créer un nouvel atelier</h1>
      <form className="v-stack gap-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="h-stack gap-2">
          <label className="text-xl">Type d'atelier :</label>
          <Select
            required
            value={getValues().categoryId}
            options={options}
            onChange={categoryId => {
              setValue('categoryId', categoryId);
              setValue('maxPersons', categories[categoryId].maxPersons);
            }}
            notSelected="Choisir un atelier"
          ></Select>
        </div>

        <div className="h-stack gap-2">
          <label className="text-xl">Lieu :</label>
          <input {...register('place')} required className="input" placeholder="Lieu" type="text" />
        </div>

        <div className="h-stack-wrap gap-4">
          <div className="h-stack gap-2">
            <label className="text-xl">Jour :</label>
            <input
              {...register('day', { valueAsDate: true })}
              required
              className="input"
              placeholder="Jour"
              type="date"
            />
          </div>
          <div className="h-stack gap-2">
            <label className="text-xl">De :</label>
            <input {...register('startHour')} required className="input" placeholder="Début" type="time" />
            <label className="text-xl">à</label>
            <input {...register('endHour')} required className="input" placeholder="Fin" type="time" />
          </div>
        </div>

        <div className="h-stack gap-2">
          <label className="text-xl">Nombre de participants :</label>
          <input
            {...register('maxPersons', { valueAsNumber: true })}
            required
            className="input"
            placeholder="Participants"
            type="number"
          />
        </div>

        <div className="v-stack gap-2">
          <label className="text-xl">A propos</label>
          <textarea {...register('description')} className="input p-3" rows={6}></textarea>
        </div>

        <div className="h-stack justify-center">
          <Display condition={isLoading}>
            <Loader />
          </Display>
          <Display condition={!isLoading}>
            <button className="btn text-xl px-8 py-2 leading-none" type="submit">
              Créer
            </button>
          </Display>
        </div>
      </form>
    </div>
  );
};
