import { Dialog, Transition } from '@headlessui/react';
import { format } from 'date-fns';
import { FC, Fragment } from 'react';
import { useFetchCoinWithHistory } from '../../../../core/usecases/queries/coins/useFetchCoinWithHistory';
import { Display } from '../../components/Display';
import { Loader } from '../../components/Loader';
import { useNavigate } from 'react-router-dom';
import './CoinsHistoryModal.css';

interface Props {
  show: boolean;
  close: () => void;
  coinId: string;
  memberId: string;
}

const ModalContent: FC<Omit<Props, 'show'>> = ({ memberId, coinId }) => {
  const { isLoading, data: coin, isError, isSuccess } = useFetchCoinWithHistory(memberId, coinId);
  const navigate = useNavigate();

  return (
    <>
      <Display condition={isLoading}>
        <Loader />
      </Display>
      <Display condition={isError}>Une erreur est survenue. Veuillez réessayer</Display>

      {isSuccess && coin ? (
        <>
          <Dialog.Title as="div" className="h-stack gap-2 justify-between">
            <h3 className="text-2xl font-medium leading-6">{coin.name}</h3>
          </Dialog.Title>
          <Dialog.Description className="mt-2 h-stack"></Dialog.Description>
          <div className="h-stack gap-2">
            <span>
              Utilisés : <span className="font-bold">{coin.used}</span>
            </span>
            <span>
              Restant : <span className="font-bold">{coin.remaining}</span>
            </span>
          </div>

          <div className="v-stack">
            <span>
              Acheté le <span className="font-bold">{format(coin.purchaseDate, 'dd/MM/yyyy')}</span>
            </span>
            <span>
              Expire le <span className="font-bold">{format(coin.expirationDate, 'dd/MM/yyyy')}</span>
            </span>
          </div>

          <h3 className="text-lg font-bold mt-3 mb-3">Historique :</h3>

          <table className="coins-history-table w-full">
            <thead>
              <tr>
                <th>Motif</th>
                <th>Date</th>
                <th>Montant</th>
              </tr>
            </thead>
            <tbody>
              {coin!.history.map((entry, i) => (
                <tr key={i} onClick={() => navigate(`/ateliers/${entry.eventId}`)}>
                  <td>{entry.reason}</td>
                  <td>{format(entry.date, 'dd/MM/yyyy')}</td>
                  <td>{entry.isCancelation ? '+1' : '-1'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </>
  );
};

export const CoinHistoryModal: FC<Props> = ({ show, close, memberId, coinId }) => {
  return (
    <Transition unmount appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <ModalContent close={close} memberId={memberId} coinId={coinId} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
