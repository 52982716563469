import { Outlet } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { TopBar } from './layout/TopBar';

function App() {
  return (
    <main className="v-stack w-full items-center">
      <TopBar />
      <div className="px-1 sm:px-4 max-w-screen-lg w-full">
        <Outlet />
      </div>
      <footer className="h-10"></footer>
      <ToastContainer draggable={false} position="bottom-left" closeOnClick={false} transition={Slide} />
    </main>
  );
}

export default App;
