import Dexie from 'dexie';
import { CategoryApi } from '../../apis/category-api';
import { Category } from '../../models/category';
import { CategoryEntity } from '../../storage/entities/category.entity';

export class DexieCategoryApi implements CategoryApi {
  public constructor(private dexie: Dexie) {}

  public async fetchAll(): Promise<Category[]> {
    const categories = await this.dexie.table<CategoryEntity>('categories').toArray();

    return categories.map(category => ({
      id: category.id.toString(),
      maxPersons: category.maxPersons,
      title: category.title
    }));
  }
}
