import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useMemo } from 'react';
import { useFetchMyEvents } from '../../../../core/usecases/queries/events/useFetchMyEvents';

export const useMyEvents = () => {
  const result = useFetchMyEvents();

  const transformedResult = useMemo(() => {
    let events: MyEvent[] | undefined;

    if (result.data) {
      events = result.data
        .sort((eventA, eventB) => eventB.start.getTime() - eventA.end.getTime())
        .map(event => ({
          id: event.id,
          title: event.title,
          date: formatDate(event.start, event.end),
          color: event.color
        }));
    }

    return {
      events,
      isFetching: result.isFetching,
      isError: result.isError,
      isSuccess: result.isSuccess
    };
  }, [result]);

  return transformedResult;
};

function formatDate(start: Date, end: Date): string {
  const day = format(start, 'EEEE dd MMMM', { locale: fr });
  const startHour = format(start, "HH'h'mm");
  const endHour = format(end, "HH'h'mm");
  return `${day} ${startHour} - ${endHour}`;
}

type MyEvent = {
  id: string;
  title: string;
  date: string;
  color: string;
};
