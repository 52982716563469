import FullCalendar, { CalendarApi } from '@fullcalendar/react';

import frLocale from '@fullcalendar/core/locales/fr';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useWindowSize } from '@uidotdev/usehooks';
import { FC, useCallback, useMemo, useState } from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useIsAdmin } from '../../../hooks/useIsAdmin';
import { Display } from '../../components/Display';
import { Error } from '../../components/Error';
import { Loader } from '../../components/Loader';
import { CalendarContext } from './CalendarContext';
import { Controls } from './Controls';
import { useFullCalendarEvents } from './useFullCalendarEvents';

export const Calendar: FC = () => {
  const [api, setApi] = useState<CalendarApi | null>(null);
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const size = useWindowSize();

  const calendarHeight = useMemo(() => {
    const otherPageHeight = 70 + 36 + 45 + 52 + 38;
    return (size.height ?? 600) - otherPageHeight;
  }, [size]);

  const setApiCallback = useCallback((calendarComponent: FullCalendar) => {
    if (calendarComponent) {
      setApi(calendarComponent.getApi());
    }
  }, []);

  const result = useFullCalendarEvents();

  if (result.isLoading) {
    return <Loader />;
  }

  if (result.isError || !result.data) {
    return <Error />;
  }

  return (
    <div className="relative">
      <Display condition={api}>
        <CalendarContext.Provider value={api!}>
          <Controls onEventClick={eventId => navigate(eventId)} />
        </CalendarContext.Provider>
      </Display>

      <FullCalendar
        ref={setApiCallback}
        headerToolbar={false}
        plugins={[dayGridPlugin, listPlugin, timeGridPlugin]}
        locales={[frLocale]}
        initialView="dayGridMonth"
        slotMinTime="08:00:00"
        allDaySlot={false}
        events={result.data}
        contentHeight={calendarHeight}
      />

      <Display condition={isAdmin}>
        <button className="btn-action" onClick={() => navigate('creer')}>
          <BsPlusLg />
        </button>
      </Display>
    </div>
  );
};
