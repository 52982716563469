import { FC, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FaCheck } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { passwordResetSelector } from '../../../../../core/store/selectors/password-reset.selector';
import { Display } from '../../../components/Display';
import { Loader } from '../../../components/Loader';
import { checkIfPasswordIsStrong } from './password-conditions';
import { useResetPassword } from './use-reset-password';

type ResetPasswordFormValues = {
  password: string;
  confirmPassword: string;
};

export const ResetPassword: FC = () => {
  const { isLoading } = useSelector(passwordResetSelector);
  const { register, handleSubmit, watch } = useForm<ResetPasswordFormValues>();
  const password = watch('password', '');

  const { resetPassword } = useResetPassword();

  const conditions = useMemo(() => checkIfPasswordIsStrong(password), [password]);
  const isFormValid = Object.values(conditions).every(condition => condition.check);

  const onSubmit: SubmitHandler<ResetPasswordFormValues> = data => {
    if (!isFormValid) {
      return;
    }
    resetPassword(data.password);
  };

  return (
    <div className="w-full h-stack justify-center">
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <div>
            <img
              className="mx-auto h-24 w-auto rounded-full p-2 box-content bg-secondary"
              src={process.env.PUBLIC_URL + '/logo.png'}
              alt="Logo À Petits Pas"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Réinitialiser mot de passe
            </h2>
          </div>
          <form className="mt-8 min-w-[300px] v-stack gap-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <input
                  id="password"
                  type="password"
                  required
                  autoComplete="password"
                  className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-ternary focus:outline-none focus:ring-ternary sm:text-sm transition duration-150 ease-in-out"
                  placeholder="Nouveau mot de passe"
                  {...register('password')}
                />
              </div>
            </div>
            <div className="text-[0.8rem] px-2">
              <span>Votre mot de passe doit respecter les règles suivantes :</span>
              <ul className="v-stack mt-1 list-disc ml-4">
                {Object.keys(conditions).map(key => (
                  <li key={key}>
                    <div className="h-stack items-center gap-1">
                      <span>{conditions[key].label}</span>
                      <Display condition={conditions[key].check}>
                        <FaCheck size={10} className="inline text-green-500 mt-0.5" />
                      </Display>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <Display condition={isLoading}>
              <div className="text-center">
                <Loader />
              </div>
            </Display>
            <div>
              <button
                type="submit"
                disabled={isLoading || !isFormValid}
                className="flex w-full justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white hover:bg-ternary hover:text-black focus:outline-none transition duration-150 ease-in-out disabled:bg-gray-500 disabled:text-white"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                Confirmer mon nouveau mot de passe
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
