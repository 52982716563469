import { createAction, createReducer } from '@reduxjs/toolkit';
import { AsyncStatus } from '../models/async-status';
import { createEvent } from '../usecases/create-event/create-event';

export type EventCreationState = {
  status: AsyncStatus;
  newEventId: string | null;
};

const initialState: EventCreationState = {
  status: 'idle',
  newEventId: null
};

export const resetEventCreationState = createAction('events/resetEventCreation');

export const eventCreationReducer = createReducer(initialState, builder => {
  builder.addCase(resetEventCreationState, () => initialState);
  builder.addCase(createEvent.pending, state => ({ ...state, status: 'pending' }));
  builder.addCase(createEvent.rejected, state => ({ ...state, status: 'failed' }));
  builder.addCase(createEvent.fulfilled, (state, { payload }) => ({
    ...state,
    newEventId: payload,
    status: 'succeeded'
  }));
});
