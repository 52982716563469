import { createAction, createReducer } from '@reduxjs/toolkit';
import { AsyncStatus } from '../models/async-status';
import { updateUser } from '../usecases/update-user/update-user';

export type UserUpdateState = {
  status: AsyncStatus;
};

const initialState: UserUpdateState = {
  status: 'idle'
};

export const resetUserUpdateState = createAction('users/resetUserUpdate');

export const userUpdateReducer = createReducer(initialState, builder => {
  builder.addCase(resetUserUpdateState, () => initialState);
  builder.addCase(updateUser.pending, state => ({ ...state, status: 'pending' }));
  builder.addCase(updateUser.rejected, state => ({ ...state, status: 'failed' }));
  builder.addCase(updateUser.fulfilled, state => ({ ...state, status: 'succeeded' }));
});
