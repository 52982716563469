import { FC } from 'react';
import { AvailableCategories } from '../../components/AvailableCategories';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../core/store/store';
import { UpdateUserCommand, updateUser } from '../../../../core/usecases/update-user/update-user';
import { Display } from '../../components/Display';
import { Loader } from '../../components/Loader';
import { useToastStatus } from '../../../hooks/use-toast-status';
import { resetUserUpdateState } from '../../../../core/store/user-update.state';
import { userUpdateStatusSelector } from '../../../../core/store/selectors/user-update-status.selector';

type UpdateAvailableCategoriesForm = {
  categories: Record<string, boolean>;
};

export const MemberAvailableCategories: FC<{ availableCategoryIds: string[]; memberId: string }> = ({
  memberId,
  availableCategoryIds
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading } = useSelector(userUpdateStatusSelector);
  const { handleSubmit, register } = useForm<UpdateAvailableCategoriesForm>({
    defaultValues: {
      categories: availableCategoryIds.reduce(
        (categories, categoryId) => ({ ...categories, [categoryId]: true }),
        {} as Record<string, boolean>
      )
    }
  });

  useToastStatus(userUpdateStatusSelector, {
    success: 'Les données ont bien été mises à jour',
    finally: () => dispatch(resetUserUpdateState())
  });

  const onSubmit: SubmitHandler<UpdateAvailableCategoriesForm> = async data => {
    const availableCategoryIds = Object.entries(data.categories)
      .filter(([categoryId, checked]) => checked)
      .map(([categoryId, checked]) => categoryId);
    const command: UpdateUserCommand = {
      userId: memberId,
      availableCategoryIds
    };
    dispatch(updateUser(command));
  };

  return (
    <div>
      <h2 className="text-2xl font-bold">Ateliers accessibles :</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AvailableCategories register={register} />
        <br />
        <Display condition={isLoading}>
          <Loader />
        </Display>
        <Display condition={!isLoading}>
          <button className="btn px-3 py-2">Mettre à jour</button>
        </Display>
      </form>
    </div>
  );
};
