import { createAction, createReducer } from '@reduxjs/toolkit';
import { AsyncStatus } from '../models/async-status';
import { LoginErrorCode } from '../models/domain-error';
import { login } from '../usecases/login/login';

export type LoginState = {
  status: AsyncStatus;
  errorCode: LoginErrorCode | null;
};

const initialState: LoginState = {
  status: 'idle',
  errorCode: null
};

export const resetLoginState = createAction('users/resetLoginState');

export const loginReducer = createReducer(initialState, builder => {
  builder.addCase(resetLoginState, () => initialState);
  builder.addCase(login.pending, state => ({ ...state, status: 'pending' }));
  builder.addCase(login.rejected, (state, { payload }) => ({
    ...state,
    errorCode: payload ?? null,
    status: 'failed'
  }));
  builder.addCase(login.fulfilled, state => ({ ...state, status: 'succeeded' }));
});
