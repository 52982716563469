import { createReducer } from '@reduxjs/toolkit';
import { User } from '../models/user';
import { login } from '../usecases/login/login';

export interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: null
};

export const userReducer = createReducer<UserState>(initialState, builder => {
  builder.addCase(login.fulfilled, (state, { payload }) => ({ ...state, user: payload }));
});
