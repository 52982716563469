export const checkIfPasswordIsStrong = (password: string): Record<string, { label: string; check: boolean }> => ({
  minLength: {
    label: '8 caractères',
    check: password.length >= 8
  },
  hasDigit: {
    label: '1 chiffre',
    check: /\d/.test(password)
  },
  hasUppercase: {
    label: '1 lettre majuscule',
    check: /[A-Z]/.test(password)
  },
  hasLowercase: {
    label: '1 lettre minuscule',
    check: /[a-z]/.test(password)
  },
  hasSpecialChar: {
    label: '1 symbole',
    // eslint-disable-next-line no-useless-escape
    check: /[!@#$%^&*()_+\-=\[\]{};:'",<>.?\/\|~]/.test(password)
  }
});
