export class TokenManager {
  private constructor() {}
  private static _token: string | null;
  private static _subscribers: OnTokenChangeSubscriber[] = [];

  public static setToken(token: string | null) {
    this._token = token;
    this._subscribers.forEach(subscriber => subscriber(token));
  }

  public static get token(): string {
    if (!this._token) {
      throw new Error('Token is not set');
    }
    return this._token;
  }

  public static onTokenChange(subscriber: OnTokenChangeSubscriber) {
    this._subscribers.push(subscriber);
  }
}

type OnTokenChangeSubscriber = (token: string | null) => void;
