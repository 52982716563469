import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { useFetchCategories } from '../../../core/usecases/queries/categories/useFetchCategories';

type Props<F extends FieldValues> = {
  register: UseFormRegister<F>;
  disabled?: boolean;
};

export const AvailableCategories = <R extends { categories: Record<string, boolean> }>({
  register,
  disabled = false
}: Props<R>): JSX.Element => {
  const categories = useFetchCategories();

  return (
    <div>
      {categories.data?.map(category => (
        <div key={category.id} className="h-stack gap-2">
          <input
            id={category.id}
            disabled={disabled}
            type="checkbox"
            {...register(`categories.${category.id}` as Path<R>)}
          />
          <label className="text-lg" htmlFor={category.id}>
            {category.title}
          </label>
        </div>
      ))}
    </div>
  );
};
