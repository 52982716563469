import { FC } from 'react';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import classNames from 'classnames';

type Size = 'md' | 'sm';

interface Props {
  className?: string;
  size?: Size;
}

const sizeStyles: Record<Size, string> = { md: 'text-3xl', sm: 'text-2xl' };

export const ImproCoin: FC<Props> = ({ className, size = 'md' }) => {
  return (
    <div className="h-stack rounded-full bg-impro-coin items-center">
      <RiMoneyDollarCircleFill className={classNames('text-black', sizeStyles[size], className)} />
      <span className="pr-2 text-sm text-black">Impro</span>
    </div>
  );
};

export const CommonCoin: FC<Props> = ({ className, size = 'md' }) => {
  return (
    <div className="h-stack rounded-full bg-common-coin items-center p-0 m-0">
      <RiMoneyDollarCircleFill className={classNames('text-black', sizeStyles[size], className)} />
      <span className="pr-2 text-sm text-black">Autres</span>
    </div>
  );
};
