import classNames from 'classnames';
import { FC } from 'react';
import { IoMdLogOut } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../core/store/store';
import { logout } from '../../../core/usecases/logout/logout';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { useIsConnected } from '../../hooks/useIsConnected';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import { Display } from '../components/Display';
import { TopBarCoins } from './TopBarCoins';

export const TopBar: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isConnected = useIsConnected();
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();

  const isDesktop = useIsDesktop();

  return (
    <>
      <header className="w-full h-[70px] h-stack justify-center bg-secondary text-white">
        <div className="w-full max-w-screen-lg h-stack justify-between items-center">
          <div className="h-stack items-center cursor-pointer" onClick={() => navigate('/ateliers')}>
            <div className="mx-5">
              <img src={process.env.PUBLIC_URL + '/logo.png'} className="object-cover w-14 rounded-full" alt="logo" />
            </div>
            <Display condition={isDesktop}>
              <h1 className="text-4xl">À Petits Pas</h1>
            </Display>
          </div>

          <Display condition={isConnected}>
            <div className="pr-5 h-stack items-center gap-3">
              <Display condition={!isAdmin}>
                <TopBarCoins />
              </Display>

              <div>
                <IoMdLogOut
                  className="text-4xl cursor-pointer transition ease-in-out duration-150 hover:text-ternary"
                  onClick={() => dispatch(logout())}
                />
              </div>
            </div>
          </Display>
        </div>
      </header>

      <Display condition={isConnected}>
        <nav className="h-10 w-full bg-ternary border-b-2 border-secondary">
          <div className="w-full h-full mx-auto px-5 max-w-screen-lg">
            <ul className="w-full h-full h-stack items-center justify-start leading-none text-lg gap-2">
              <NavLink
                to="/ateliers"
                className={({ isActive }) =>
                  classNames('hover:underline hover:cursor-pointer', isActive && 'underline')
                }
              >
                Calendrier
              </NavLink>
              <Display condition={isAdmin}>
                <NavLink
                  to="/adherents"
                  className={({ isActive }) =>
                    classNames('hover:underline hover:cursor-pointer', isActive && 'underline')
                  }
                >
                  Adhérents
                </NavLink>
              </Display>
              <Display condition={!isAdmin}>
                <NavLink
                  to="/mes-ateliers"
                  className={({ isActive }) =>
                    classNames('hover:underline hover:cursor-pointer', isActive && 'underline')
                  }
                >
                  Mes ateliers
                </NavLink>
                <NavLink
                  to="/mes-credits"
                  className={({ isActive }) =>
                    classNames('hover:underline hover:cursor-pointer', isActive && 'underline')
                  }
                >
                  Crédits
                </NavLink>
              </Display>
            </ul>
          </div>
        </nav>
      </Display>
      <hr className="mb-10 h-0 w-0" />
    </>
  );
};
