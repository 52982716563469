import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { DependenciesContext } from '../../../dependencies/dependencies-context';

export function useFetchCategories() {
  const { categoryApi } = useContext(DependenciesContext);
  return useQuery(
    ['categories'],
    async () => {
      const categories = await categoryApi.fetchAll();
      return categories;
    },
    { refetchOnMount: false } // does not refetch categories every time
  );
}
