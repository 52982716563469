import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../store';
import { asyncStatusSelector } from './async-status.selector';

const selectLoginStatus = (state: AppState) => state.loginState.status;
const selectLoginError = (state: AppState) => state.loginState.errorCode;

export const loginStatusSelector = createSelector(selectLoginStatus, selectLoginError, (status, errorCode) => ({
  ...asyncStatusSelector(status),
  errorCode
}));
