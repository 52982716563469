import { FC, useMemo, useState } from 'react';
import { DetailedMember } from '../../../../core/models/detailed-member';
import { CoinCard } from './CoinCard';
import { CoinHistoryModal } from './CoinsHistoryModal';
import { AddCoinsModal } from './AddCoinsModal';

export const MemberCoins: FC<{ member: DetailedMember }> = ({ member }) => {
  const [isAddCoinsModalOpen, setIsAddCoinsModal] = useState(false);
  const fullName = useMemo(() => `${member.firstName} ${member.lastName.toLocaleUpperCase()}`, [member]);

  const [isCoinHistoryModalOpen, setIsCoinHistoryModalOpen] = useState(false);
  const [selectedCoinId, setSelectedCoinId] = useState<string>();

  const openCoinHistoryModal = (coinId: string) => {
    setSelectedCoinId(coinId);
    setIsCoinHistoryModalOpen(true);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold">Crédits :</h2>

      <div className="v-stack gap-5">
        <h3 className="text-lg font-bold">Crédits impro</h3>
        <div className="h-stack-wrap gap-3">
          {member.coins.impro.map(coin => (
            <CoinCard key={coin.id} coin={coin} categoryType="impro" openCoinHistoryModal={openCoinHistoryModal} />
          ))}
        </div>

        <h3 className="text-lg font-bold">Crédits autres ateliers</h3>
        <div className="h-stack-wrap gap-3">
          {member.coins.common.map(coin => (
            <CoinCard key={coin.id} coin={coin} categoryType="common" openCoinHistoryModal={openCoinHistoryModal} />
          ))}
        </div>

        <div>
          <button className="btn px-3 py-2" onClick={() => setIsAddCoinsModal(true)}>
            Ajouter des crédits
          </button>
        </div>

        <AddCoinsModal
          fullName={fullName}
          show={isAddCoinsModalOpen}
          close={() => setIsAddCoinsModal(false)}
          userId={member.id}
        />
        <CoinHistoryModal
          close={() => setIsCoinHistoryModalOpen(false)}
          show={isCoinHistoryModalOpen}
          coinId={selectedCoinId!}
          memberId={member.id}
        />
      </div>
    </div>
  );
};
