import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useContext } from 'react';
import { DependenciesContext } from '../../../dependencies/dependencies-context';
import { Coins } from '../../../models/coin';

export function useFetchCoins(): UseQueryResult<Coins> {
  const { userApi } = useContext(DependenciesContext);

  return useQuery(['coins'], () => userApi.getCoins(), {
    refetchOnMount: false // do not refetch coins every time
  });
}
