import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useContext } from 'react';
import { DependenciesContext } from '../../../dependencies/dependencies-context';
import { CoinWithHistory } from '../../../models/coin';

export function useFetchCoinWithHistory(memberId: string, coinId: string): UseQueryResult<CoinWithHistory> {
  const { userApi } = useContext(DependenciesContext);

  return useQuery(['coin'], () => userApi.getCoinWithHistory(memberId, coinId));
}
