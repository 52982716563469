type DomainErrorCode = LoginErrorCode;

abstract class DomainError extends Error {
  public constructor(public code: DomainErrorCode) {
    super(undefined);
  }
}

export type LoginErrorCode = 'E_WRONG_CREDENTIALS';

export class LoginError extends DomainError {
  public constructor(code: LoginErrorCode) {
    super(code);
  }
}
