import { CalendarListeners } from '@fullcalendar/react';
import { useContext, useEffect } from 'react';
import { CalendarContext } from './CalendarContext';

export function useListenEventHook<ListenerName extends keyof CalendarListeners>(
  handlerName: ListenerName,
  handler: CalendarListeners[ListenerName]
) {
  const api = useContext(CalendarContext);

  useEffect(() => {
    api.on(handlerName, handler);

    return () => {
      api.off(handlerName, handler);
    };
  }, [api, handler, handlerName]);
}
