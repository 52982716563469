import { FC } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { CategoryType } from '../../../../core/models/category-type';
import { Coin } from '../../../../core/models/coin';

interface Props {
  coin: Coin;
  categoryType: CategoryType;
  openCoinHistoryModal: (coinId: string) => void;
}

export const CoinCard: FC<Props> = ({ coin, categoryType, openCoinHistoryModal }) => {
  return (
    <div
      className={classNames(
        'v-stack gap-2 items-center rounded-md border border-black p-4 transition ease-in-out duration-150 hover:cursor-pointer',
        categoryType === 'common' && 'bg-common-coin/25 hover:bg-common-coin/50',
        categoryType === 'impro' && 'bg-impro-coin/25 hover:bg-impro-coin/50'
      )}
      onClick={() => openCoinHistoryModal(coin.id)}
    >
      <span className="font-bold">{coin.name}</span>

      <div className="h-stack gap-2">
        <span>
          Utilisés : <span className="font-bold">{coin.used}</span>
        </span>
        <span>
          Restant : <span className="font-bold">{coin.remaining}</span>
        </span>
      </div>

      <div className="v-stack">
        <span>
          Acheté le <span className="font-bold">{format(coin.purchaseDate, 'dd/MM/yyyy')}</span>
        </span>
        <span>
          Expire le <span className="font-bold">{format(coin.expirationDate, 'dd/MM/yyyy')}</span>
        </span>
      </div>
    </div>
  );
};
