import { useMemo } from 'react';
import { useFetchCoins } from './useFetchCoins';
import { UseQueryResult } from '@tanstack/react-query';
import { CoinsAmounts } from '../../../models/coin';

export const useCoinsAmounts = (): UseQueryResult<CoinsAmounts> => {
  const coins = useFetchCoins();

  return useMemo(() => {
    if (coins.data) {
      const coinsAmounts: CoinsAmounts = {
        common: coins.data.common.reduce((total, coin) => total + coin.remaining, 0),
        impro: coins.data.impro.reduce((total, coin) => total + coin.remaining, 0)
      };
      return { ...coins, data: coinsAmounts };
    }
    return coins;
  }, [coins]) as UseQueryResult<CoinsAmounts>;
};
