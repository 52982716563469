import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store/store';
import { CategoryType } from '../../models/category-type';

export type AddCoinsCommand = {
  userId: string;
  categoryType: CategoryType;
  name: string;
  expirationDate: Date;
  total: number;
};

export const addCoins = createAsyncThunk<void, AddCoinsCommand, ThunkConfig>(
  'users/addCoins',
  (command, { extra: { userApi } }) => userApi!.addCoins(command)
);
