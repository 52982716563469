import { createAction, createReducer } from '@reduxjs/toolkit';
import { AsyncStatus } from '../models/async-status';
import { registerToEvent } from '../usecases/register-to-event/event-registration';

export type EventRegistrationState = {
  status: AsyncStatus;
};

const initialState: EventRegistrationState = {
  status: 'idle'
};

export const resetEventRegistrationState = createAction('events/resetEventRegistration');

export const eventRegistrationReducer = createReducer(initialState, builder => {
  builder.addCase(resetEventRegistrationState, () => initialState);
  builder.addCase(registerToEvent.pending, state => ({ ...state, status: 'pending' }));
  builder.addCase(registerToEvent.rejected, state => ({ ...state, status: 'failed' }));
  builder.addCase(registerToEvent.fulfilled, state => ({ ...state, status: 'succeeded' }));
});
