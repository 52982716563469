import { FC, PropsWithChildren } from 'react';

const Bold: FC<PropsWithChildren<{}>> = ({ children }) => {
  return <span className="font-bold"> {children} </span>;
};

export const MyCoins: FC = () => {
  return (
    <div className="p-4">
      <h1 className="text-4xl underline mb-10 decoration-primary">Crédits</h1>
      <p>
        <span className="text-danger font-bold text-lg">Attention !</span> <br />
        <Bold>
          Vous devez utiliser la même adresse email pour l'achat de crédit sur HelloAsso que celle pour vous connectez à
          Agenda APP.
        </Bold>
        <br />
        La mise à jour de vos crédits sur le site <Bold>n'est pas encore instantané</Bold>. Ceci arrivera dans une
        prochaine mise à jour du site.
        <br />
        Pour l'instant la mise à jour des crédits se fait donc <Bold>manuellement</Bold>. Après réception de l'email de
        confirmation de l'achat de la part de HelloAsso, Ehlios ajoute les crédits à votre compte Agenda APP.
        <br />
        Il peut donc y avoir un délai de <Bold>1 à 2 jours</Bold> entre votre achat sur HelloAsso et l'apparition des
        crédits sur votre compte Agenda APP. Merci donc de patienter pendant ce délai. Ehlios consulte ses emails 1 fois
        par jour et fait le maximum pour répondre au plus vite à vos demandes.
      </p>
      <br />
      <p>
        Merci de <Bold>vérifier</Bold> que vous avez bien reçu un email de confirmation après votre paiement. Si vous ne
        l'avez pas reçu, c'est que le paiement ne s'est pas effectué. Cela arrive fréquemment que le site HelloAsso bug
        sur smartphone. Il est recommandé d'acheter vos crédits depuis un ordinateur.
      </p>
      <br />
      <p>
        Pour rappel il y a un <span className="font-bold"> TARIF RÉDUIT</span> accessible aux personnes précaires sur
        justificatif. Le tarif réduit est uniquement applicable pour les atelier d'impro et son prix est de 20€. Les
        justificatifs valables sont : Attestation CAF RSA / AAH / pension d'invalidité, quotient familial 1, 2 ou 3,
        attestation demandeur d'emploi ou certificat de scolarité étudiant. Faites en la demande par mail en envoyant
        votre justificatif à :{' '}
        <a href="mailto:ateliers.app@gmail.com" className="underline text-link">
          ateliers.app@gmail.com
        </a>
        .
      </p>
      <br />
      <div>
        <span className="font-bold text-lg">
          Vous pouvez acheter des crédits en cliquant sur les liens ci-dessous :
        </span>
      </div>
      <br />
      <ul>
        <li>
          <a
            href="https://www.helloasso.com/associations/a-petits-pas-a-p-p/evenements/pass-unitaire-pour-un-atelier-d-impro-tarif-reduit"
            target="_blank"
            rel="noreferrer"
            className="text-link hover:underline font-bold text-lg"
          >
            Acheter un pass impro
          </a>
        </li>

        <li>
          <a
            href="https://www.helloasso.com/associations/a-petits-pas-a-p-p/evenements/pack-5-ateliers"
            target="_blank"
            rel="noreferrer"
            className="text-link hover:underline font-bold text-lg"
          >
            Acheter un pack impro
          </a>
        </li>

        <li>
          <a
            href="https://www.helloasso.com/associations/a-petits-pas-a-p-p/evenements/pass-unitaire-autres-ateliers-prise-de-parole-niv-1-conversation-stand-up"
            target="_blank"
            rel="noreferrer"
            className="text-link hover:underline font-bold text-lg"
          >
            Acheter un pass autres ateliers (prise de parole, conversation, stand-up, etc)
          </a>
        </li>
      </ul>
    </div>
  );
};
