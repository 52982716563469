import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { useIsConnected } from '../../hooks/useIsConnected';

interface Props {
  adminOnly?: boolean;
  children: React.ReactNode;
}

export const AuthenticatedGuard: FC<Props> = ({ children, adminOnly }) => {
  const isConnected = useIsConnected();
  const isAdmin = useIsAdmin();

  if (!isConnected || (adminOnly && !isAdmin)) {
    return <Navigate to="/connexion" replace />;
  }

  return <>{children}</>;
};
