import { Navigate, redirect, Route } from 'react-router-dom';
import { AuthenticatedGuard } from './ui/components/AuthenticatedGuard';
import { NotAuthenticatedGuard } from './ui/components/NotAuthenticatedGuard';
import { Login } from './ui/pages/authentification/login/Login';
import { Calendar } from './ui/pages/calendar/Calendar';
import { CreateEvent } from './ui/pages/create-event/CreateEvent';
import { Event } from './ui/pages/event/Event';
import { CreateMember } from './ui/pages/member/CreateMember';
import { Member } from './ui/pages/member/Member';
import { Members } from './ui/pages/member/Members';
import { MyCoins } from './ui/pages/my-coins/MyCoins';
import { MyEvents } from './ui/pages/my-events/MyEvents';
import { EditEvent } from './ui/pages/edit-event/EditEvent';
import { ForgotPassword } from './ui/pages/authentification/forgot-password/ForgotPassword';
import { ResetPassword } from './ui/pages/authentification/reset-password/ResetPassword';
import App from './ui/App';

export const routes = (
  <Route path="/" element={<App />}>
    <Route
      path="/mot-de-passe-oublie"
      element={
        <NotAuthenticatedGuard>
          <ForgotPassword />
        </NotAuthenticatedGuard>
      }
    />
    <Route
      path="/reinitialisation-mot-de-passe"
      loader={({ request }) => {
        const url = new URL(request.url);
        const token = url.searchParams.get('t');
        if (!token) {
          return redirect('/login');
        }
        return token;
      }}
      element={
        <NotAuthenticatedGuard>
          <ResetPassword />
        </NotAuthenticatedGuard>
      }
    />
    <Route
      path="/connexion"
      element={
        <NotAuthenticatedGuard>
          <Login />
        </NotAuthenticatedGuard>
      }
    />
    <Route
      path="/mes-credits"
      element={
        <AuthenticatedGuard>
          <MyCoins />
        </AuthenticatedGuard>
      }
    />
    <Route
      path="/mes-ateliers"
      element={
        <AuthenticatedGuard>
          <MyEvents />
        </AuthenticatedGuard>
      }
    />
    <Route
      path="/ateliers/creer"
      element={
        <AuthenticatedGuard adminOnly>
          <CreateEvent />
        </AuthenticatedGuard>
      }
    />
    <Route
      path="/ateliers/:id/modifier"
      element={
        <AuthenticatedGuard>
          <EditEvent />
        </AuthenticatedGuard>
      }
    />
    <Route
      path="/ateliers/:id"
      element={
        <AuthenticatedGuard>
          <Event />
        </AuthenticatedGuard>
      }
    />
    <Route
      path="/ateliers"
      element={
        <AuthenticatedGuard>
          <Calendar />
        </AuthenticatedGuard>
      }
    />
    <Route
      path="/adherents/creer"
      element={
        <AuthenticatedGuard>
          <CreateMember />
        </AuthenticatedGuard>
      }
    />
    <Route
      path="/adherents/:id"
      element={
        <AuthenticatedGuard>
          <Member />
        </AuthenticatedGuard>
      }
    />
    <Route
      path="/adherents"
      element={
        <AuthenticatedGuard adminOnly>
          <Members />
        </AuthenticatedGuard>
      }
    />
    <Route index element={<Navigate to={'/connexion'} />} />
    <Route path="*" element={<Navigate to={'/connexion'} />} />
  </Route>
);
