import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store/store';

export type RegisterToEventCommand = {
  eventId: string;
};

export const registerToEvent = createAsyncThunk<void, RegisterToEventCommand, ThunkConfig>(
  'events/registerToEvent',
  (command, { extra: { eventApi } }) => eventApi!.registerToEvent(command.eventId)
);
