import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store/store';

export type UnregisterToEventCommand = {
  eventId: string;
};

export const unregisterFromEvent = createAsyncThunk<void, UnregisterToEventCommand, ThunkConfig>(
  'events/unregisterFromEvent',
  (command, { extra: { eventApi } }) => eventApi!.unregisterFromEvent(command.eventId)
);
