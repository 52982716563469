import { createAction, createReducer } from '@reduxjs/toolkit';
import { AsyncStatus } from '../models/async-status';
import { updateEvent } from '../usecases/update-event/update-event';

export type EventUpdateState = {
  status: AsyncStatus;
};

const initialState: EventUpdateState = {
  status: 'idle'
};

export const resetEventUpdateState = createAction('events/resetEventUpdate');

export const eventUpdateReducer = createReducer(initialState, builder => {
  builder.addCase(resetEventUpdateState, () => initialState);
  builder.addCase(updateEvent.pending, state => ({ ...state, status: 'pending' }));
  builder.addCase(updateEvent.rejected, state => ({ ...state, status: 'failed' }));
  builder.addCase(updateEvent.fulfilled, state => ({ ...state, status: 'succeeded' }));
});
