import { FC } from 'react';

interface Props {
  value: string | null;
  options: { label: string; value: string }[];
  onChange: (value: string) => void;
  required?: boolean;
  notSelected?: string;
}

export const Select: FC<Props> = ({ onChange, value, required, notSelected, options }) => {
  return (
    <select
      required={required}
      className="btn text-lg"
      value={value ?? undefined}
      onChange={v => onChange(v.target.value)}
    >
      <option hidden value="">
        {notSelected ?? ''}
      </option>
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};
