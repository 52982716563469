import { FC } from 'react';
import { IconType } from 'react-icons';

interface Props {
  Icon: IconType;
  title: string;
  content: string;
}

export const EventInformation: FC<Props> = ({ Icon, title, content }) => {
  return (
    <div className="h-stack gap-4">
      <Icon className="text-2xl mt-2 text-secondary bg-ternary p-2 box-content rounded-md" />
      <div className="v-stack">
        <span className="text-lg">{title}</span>
        <span className="text-gray-500">{content}</span>
      </div>
    </div>
  );
};
