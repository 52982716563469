import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { DependenciesContext } from '../../../dependencies/dependencies-context';

export function useFetchMembers() {
  const { userApi } = useContext(DependenciesContext);
  return useQuery(['members'], async () => {
    const members = await userApi.fetchAll();
    members.sort((m1, m2) => m1.firstName.localeCompare(m2.firstName));
    return members;
  });
}
