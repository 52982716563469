import { createAction, createReducer } from '@reduxjs/toolkit';
import { AsyncStatus } from '../models/async-status';
import { sendResetPasswordEmail } from '../usecases/send-reset-password-email/send-reset-password-email';

export type ResetPasswordEmailSendingState = {
  status: AsyncStatus;
};

const initialState: ResetPasswordEmailSendingState = {
  status: 'idle'
};

export const resetResetPasswordEmailSendingState = createAction('users/resetResetPasswordEmailSendingState');

export const resetPasswordEmailSendingStateReducer = createReducer(initialState, builder => {
  builder.addCase(resetResetPasswordEmailSendingState, () => initialState);
  builder.addCase(sendResetPasswordEmail.pending, state => ({ ...state, status: 'pending' }));
  builder.addCase(sendResetPasswordEmail.rejected, state => ({ ...state, status: 'failed' }));
  builder.addCase(sendResetPasswordEmail.fulfilled, state => ({ ...state, status: 'succeeded' }));
});
