import { createAsyncThunk } from '@reduxjs/toolkit';
import { User } from '../../models/user';
import { ThunkConfig } from '../../store/store';
import { LoginError, LoginErrorCode } from '../../models/domain-error';

export const login = createAsyncThunk<User, { email: string; password: string }, ThunkConfig<LoginErrorCode | null>>(
  'users/login',
  async ({ email, password }, { rejectWithValue, extra: { userApi } }) => {
    try {
      const user = await userApi!.login(email, password);
      return user;
    } catch (error) {
      if (error instanceof LoginError) {
        return rejectWithValue(error.code);
      }
      return rejectWithValue(null);
    }
  }
);
