import { Dialog, Transition } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { FC, Fragment } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryType } from '../../../../core/models/category-type';
import { coinsAdditionStatusSelector } from '../../../../core/store/selectors/coins-addition-status.selector';
import { AppDispatch } from '../../../../core/store/store';
import { Display } from '../../components/Display';
import { Loader } from '../../components/Loader';
import { addCoins } from '../../../../core/usecases/add-coins/add-coins';
import { useToastStatus } from '../../../hooks/use-toast-status';
import { resetCoinsAdditionState } from '../../../../core/store/coins-additions.state';

interface Props {
  show: boolean;
  close: () => void;
  fullName: string;
  userId: string;
}

const ModalContent: FC<Omit<Props, 'show'>> = ({ close, fullName, userId }) => {
  const { isLoading, isIdle } = useSelector(coinsAdditionStatusSelector);
  const dispatch = useDispatch<AppDispatch>();
  const client = useQueryClient();

  const { register, handleSubmit } = useForm<FormValues>();
  const onSubmit: SubmitHandler<FormValues> = data => {
    dispatch(addCoins({ ...data, userId }));
  };

  type FormValues = {
    categoryType: CategoryType;
    name: string;
    expirationDate: Date;
    total: number;
  };

  useToastStatus(coinsAdditionStatusSelector, {
    success: `Les crédits ont bien été ajoutés`,
    onSuccess: async () => {
      await client.invalidateQueries(['member']);
    },
    finally: () => {
      dispatch(resetCoinsAdditionState());
      close();
    }
  });

  return (
    <>
      <Dialog.Title as="div" className="h-stack gap-2 justify-between">
        <h3 className="text-2xl font-medium leading-6">Ajouter crédits à {fullName}</h3>
      </Dialog.Title>

      <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="v-stack gap-2 max-w-sm mx-auto mb-6">
          <div className="h-stack justify-between gap-2">
            <label className="font-bold text-lg">Nom des crédits</label>
            <input
              type="text"
              required
              className="input"
              {...register('name')}
              placeholder="Pack impro, crédit à l'unité, etc..."
            />
          </div>

          <div className="h-stack justify-between gap-2">
            <label className="font-bold text-lg">Nombre de crédits</label>
            <input
              type="number"
              defaultValue={1}
              required
              step={1}
              min={1}
              className="input w-16"
              {...register('total', { valueAsNumber: true })}
            />
          </div>

          <div className="h-stack justify-between gap-2">
            <label className="font-bold text-lg">Type de crédits</label>
            <select className="input" required {...register('categoryType')}>
              <option value="impro">Impro</option>
              <option value="common">Autres</option>
            </select>
          </div>

          <div className="h-stack justify-between gap-2">
            <label className="font-bold text-lg">Date d'expiration</label>
            <input required type="date" className="input" {...register('expirationDate', { valueAsDate: true })} />
          </div>
        </div>

        <Display condition={isIdle}>
          <div className="h-stack gap-2 justify-end mt-2">
            <button type="button" className="btn-secondary !py-1 px-2 text-lg" onClick={close}>
              Retour
            </button>
            <button type="submit" className="btn !py-1 px-2 text-lg">
              Confirmer
            </button>
          </div>
        </Display>

        <Display condition={isLoading}>
          <div className="mt-4 h-stack justify-center">
            <Loader />
          </div>
        </Display>
      </form>
    </>
  );
};

export const AddCoinsModal: FC<Props> = ({ show, close, fullName, userId }) => {
  const { isLoading } = useSelector(coinsAdditionStatusSelector);
  const closeDialogByClickingOutside = () => (isLoading ? close() : undefined);

  return (
    <Transition unmount appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeDialogByClickingOutside}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <ModalContent close={close} fullName={fullName} userId={userId} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
