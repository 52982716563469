import { createAction, createReducer } from '@reduxjs/toolkit';
import { AsyncStatus } from '../models/async-status';
import { addCoins } from '../usecases/add-coins/add-coins';

export type CoinsAdditionState = {
  status: AsyncStatus;
};

const initialState: CoinsAdditionState = {
  status: 'idle'
};

export const resetCoinsAdditionState = createAction('users/resetCoinsAddition');

export const coinsAdditionReducer = createReducer(initialState, builder => {
  builder.addCase(resetCoinsAdditionState, () => initialState);
  builder.addCase(addCoins.pending, state => ({ ...state, status: 'pending' }));
  builder.addCase(addCoins.rejected, state => ({ ...state, status: 'failed' }));
  builder.addCase(addCoins.fulfilled, state => ({ ...state, status: 'succeeded' }));
});
