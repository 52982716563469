import { useMediaQuery } from 'react-responsive';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config';

const config = resolveConfig(tailwindConfig) as any;

export const useIsDesktop = () => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${config.theme.screens.sm})`
  });
  return isDesktop;
};
