import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Display } from '../../components/Display';
import { Error } from '../../components/Error';
import { Loader } from '../../components/Loader';
import './MyEvents.css';
import { useMyEvents } from './useMyEvents';

export const MyEvents: FC = () => {
  const { events, isFetching, isError, isSuccess } = useMyEvents();
  const navigate = useNavigate();

  return (
    <div className="p-4">
      <h1 className="text-4xl underline mb-10 decoration-primary">Mes ateliers</h1>

      <Display condition={isFetching}>
        <Loader />
      </Display>

      <Display condition={!isFetching && isError}>
        <Error />
      </Display>

      <Display condition={!isFetching && isSuccess}>
        <table className="my-events-table">
          <thead>
            <tr>
              <th>Atelier</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {events?.map(event => (
              <tr key={event.id} onClick={() => navigate(`/ateliers/${event.id}`)}>
                <td style={{ color: event.color }}>{event.title}</td>
                <td>{event.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Display>
    </div>
  );
};
