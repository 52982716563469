import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resetResetPasswordEmailSendingState } from '../../../../../core/store/reset-password-email-sending.state';
import { resetPasswordEmailSendingStatusSelector } from '../../../../../core/store/selectors/reset-password-email-sending.selector';
import { AppDispatch } from '../../../../../core/store/store';
import { sendResetPasswordEmail } from '../../../../../core/usecases/send-reset-password-email/send-reset-password-email';

export const useForgotPassword = () => {
  const { hasSucceeded } = useSelector(resetPasswordEmailSendingStatusSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (hasSucceeded) {
      toast.success('Un email de réinitialisation vous a été envoyé. Veuillez vérifier votre boîte mail.', {
        autoClose: false
      });
      navigate('/login');
    }
  }, [hasSucceeded, navigate]);

  useEffect(() => {
    return () => {
      dispatch(resetResetPasswordEmailSendingState());
    };
  }, [dispatch]);

  return {
    sendResetPasswordEmail: (email: string) => dispatch(sendResetPasswordEmail({ email }))
  };
};
