import { compareDesc, isAfter } from 'date-fns';
import Dexie from 'dexie';
import { AddCoinsPayload, CreateAccountPayload, UpdateUserPayload, UserApi } from '../../apis/user-api';
import { CategoryType } from '../../models/category-type';
import { Coin, CoinWithHistory, Coins } from '../../models/coin';
import { DetailedMember } from '../../models/detailed-member';
import { LoginError } from '../../models/domain-error';
import { Member } from '../../models/member';
import { User } from '../../models/user';
import { CoinEntity, UserEntity } from '../../storage/entities/user.entity';
import { TokenManager } from '../../token/token-manager';
import { promisedTimeout } from '../../utils/promised-timeout';
import { WithoutId } from '../../utils/without-id';
import { wait } from '@testing-library/user-event/dist/utils';

export class DexieUserApi implements UserApi {
  private tokenStorage = new TokenStorage();

  public constructor(private dexie: Dexie) {}

  public async login(email: string, password: string): Promise<User> {
    const foundUser = await this.dexie.table<UserEntity>('users').where({ email, password }).first();

    await promisedTimeout(2000);

    if (!foundUser) {
      throw new LoginError('E_WRONG_CREDENTIALS');
    }

    return {
      isAdmin: foundUser.isAdmin,
      token: foundUser.id.toString()
    };
  }

  public async resetPassword(password: string, token: string): Promise<void> {
    const email = this.tokenStorage.findEmailFromToken(token);

    if (!email) {
      throw new Error('Token not found');
    }
    await this.dexie.table<UserEntity>('users').where({ email }).modify({ password });
    this.tokenStorage.removeToken(token);
  }

  public async sendResetPasswordEmail(email: string): Promise<void> {
    const token = Math.random().toString(36).substring(7);
    this.tokenStorage.storeToken({ email, token });
    await wait(1000);
    console.log('Sending reset password email to:', email, 'with token:', token);
  }

  public async getCoins(): Promise<Coins> {
    const userId = this.getCurrentUserId();
    const user = await this.dexie.table<UserEntity>('users').get(userId);
    if (!user) {
      throw new Error('User not found');
    }

    return this.calculUserCoins(user.coins);
  }

  public async getCoinWithHistory(memberId: string, coinId: string): Promise<CoinWithHistory> {
    const user = await this.getUser(memberId);

    const coin = user.coins.find(coin => coin.id === +coinId);

    if (!coin) {
      throw new Error('Coin not found');
    }

    const total = coin.total;
    const used =
      coin.history.filter(entry => !entry.isCancelation).length -
      coin.history.filter(entry => entry.isCancelation).length;
    const remaining = total - used;

    return {
      id: coin.id.toString(),
      name: coin.name,
      expirationDate: coin.expirationDate,
      purchaseDate: coin.purchaseDate,
      hasExpired: isAfter(new Date(), coin.expirationDate),
      total,
      used,
      remaining,
      history: coin.history
        .map(entry => ({ ...entry, eventId: entry.eventId.toString() }))
        .sort((entryA, entryB) => compareDesc(entryA.date, entryB.date))
    };
  }

  public async fetchAll(): Promise<Member[]> {
    const users = (await this.dexie.table<UserEntity>('users').toArray()).filter(user => !user.isAdmin);
    await promisedTimeout(500);
    return users.map(user => ({
      id: user.id.toString(),
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName
    }));
  }

  public async fetchOne(id: string): Promise<DetailedMember> {
    const user = await this.getUser(id);
    await promisedTimeout(300);

    return {
      id: user.id.toString(),
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      coins: this.calculUserCoins(user.coins),
      registrationDate: user.registrationDate,
      availableCategoryIds: user.availableCategoryIds.map(id => id.toString())
    };
  }

  private calculUserCoins(coins: CoinEntity[]): Coins {
    const getCoinsForCategoryType = (type: CategoryType): Coin[] =>
      coins
        .filter(coin => coin.categoryType === type)
        .map(coin => {
          const total = coin.total;
          const used =
            coin.history.filter(entry => !entry.isCancelation).length -
            coin.history.filter(entry => entry.isCancelation).length;
          const remaining = total - used;

          return {
            id: coin.id.toString(),
            name: coin.name,
            expirationDate: coin.expirationDate,
            hasExpired: isAfter(new Date(), coin.expirationDate),
            purchaseDate: coin.purchaseDate,
            used,
            total,
            remaining
          };
        });

    return {
      common: getCoinsForCategoryType('common'),
      impro: getCoinsForCategoryType('impro')
    };
  }

  public async addCoins(payload: AddCoinsPayload): Promise<void> {
    const user = await this.getUser(payload.userId);

    const coins = user.coins;

    const newId = coins.reduce((prev, cur) => (cur.id > prev ? cur.id : prev), 0) + 1;

    coins.push({
      id: newId,
      categoryType: payload.categoryType,
      expirationDate: payload.expirationDate,
      history: [],
      name: payload.name,
      total: payload.total,
      purchaseDate: new Date()
    });

    await promisedTimeout(700);

    await this.dexie.table<UserEntity>('users').update(user.id, { coins });
  }

  public async createAccount(payload: CreateAccountPayload): Promise<void> {
    await this.dexie.table<WithoutId<UserEntity>>('users').add({
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      password: 'qwerty',
      availableCategoryIds: payload.availableCategoryIds.map(categoryId => +categoryId),
      coins: [],
      isAdmin: false,
      registrationDate: new Date()
    });
  }

  public async updateUser(payload: UpdateUserPayload): Promise<void> {
    const userId = +payload.userId;
    await this.dexie
      .table<UserEntity>('users')
      .update(userId, { availableCategoryIds: payload.availableCategoryIds.map(id => +id) });
  }

  private async getUser(id: string | number): Promise<UserEntity> {
    const user = await this.dexie.table<UserEntity>('users').get(+id);
    if (!user) {
      throw new Error('User not found');
    }
    return user;
  }

  private getCurrentUserId(): number {
    return +TokenManager.token;
  }
}
class TokenStorage {
  public storeToken(data: { email: string; token: string }) {
    const tokens = this.getTokens();
    tokens.push(data);
    this.setTokens(tokens);
  }

  public findEmailFromToken(token: string): string | undefined {
    return this.getTokens().find(t => t.token === token)?.email;
  }

  public removeToken(token: string) {
    const tokens = this.getTokens();
    const tokenIndex = tokens.findIndex(t => t.token === token);
    if (tokenIndex !== -1) {
      tokens.splice(tokenIndex, 1);
      this.setTokens(tokens);
    }
  }

  private getTokens(): { email: string; token: string }[] {
    return JSON.parse(sessionStorage.getItem('tokens') ?? '[]');
  }

  private setTokens(tokens: { email: string; token: string }[]) {
    sessionStorage.setItem('tokens', JSON.stringify(tokens));
  }
}
