import { format } from 'date-fns';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DetailedMember } from '../../../../core/models/detailed-member';
import { useFetchMember } from '../../../../core/usecases/queries/members/useFetchMember';
import { Display } from '../../components/Display';
import { Loader } from '../../components/Loader';
import { MemberAvailableCategories } from './MemberAvailableCategories';
import { MemberCoins } from './MemberCoins';

const MemberContent: FC<{ member: DetailedMember }> = ({ member }) => {
  const registrationDate = useMemo(() => format(member.registrationDate, 'dd/MM/yyyy'), [member]);

  return (
    <div className="v-stack gap-8">
      <div>
        <h2 className="text-2xl font-bold">Infos :</h2>

        <div className="v-stack">
          <div className="h-stack gap-2">
            <span>
              <label className="font-bold">Prénom :</label> {member.firstName}
            </span>
            <span>
              <label className="font-bold"> Nom :</label> {member.lastName}
            </span>
          </div>
          <span>
            <label className="font-bold">Email :</label> {member.email}
          </span>
          <span>
            <label className="font-bold">Inscrit le :</label> {registrationDate}
          </span>
        </div>
      </div>

      <MemberCoins member={member} />
      <MemberAvailableCategories memberId={member.id} availableCategoryIds={member.availableCategoryIds} />
    </div>
  );
};

export const Member: FC = () => {
  const { id } = useParams<'id'>();
  const { data, isSuccess, isError, isFetching } = useFetchMember(id!);

  return (
    <div>
      <h1 className="text-4xl underline mb-10 decoration-primary">Adhérent</h1>
      <Display condition={isSuccess && !isFetching}>
        <MemberContent member={data!} />
      </Display>
      <Display condition={isFetching}>
        <Loader />
      </Display>
      <Display condition={isError}>Une erreur est survenue. Veuillez réessayer.</Display>
    </div>
  );
};
