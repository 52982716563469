import { FC } from 'react';
import { useFetchMembers } from '../../../../core/usecases/queries/members/useFetchMembers';
import './Members.css';
import { Display } from '../../components/Display';
import { Loader } from '../../components/Loader';
import { useNavigate } from 'react-router-dom';
import { BsPlusLg } from 'react-icons/bs';

export const Members: FC = () => {
  const { data, isFetching, isSuccess, isError } = useFetchMembers();
  const navigate = useNavigate();

  return (
    <div>
      <h1 className="text-4xl underline mb-10 decoration-primary">Adhérents</h1>

      {isSuccess && !isFetching && data ? (
        <>
          <table className="members-table">
            <thead>
              <tr>
                <th>Prénom</th>
                <th>Nom</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {data!.map(member => (
                <tr key={member.id} onClick={() => navigate(member.id)}>
                  <td>{member.firstName}</td>
                  <td>{member.lastName}</td>
                  <td>{member.email}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <button
            className="w-14 h-14 bg-ternary text-secondary border-2 border-secondary rounded-full p-1 text-2xl h-stack justify-center items-center fixed bottom-2 right-2 sm:bottom-5 sm:right-5 z-10 transition hover:scale-110 hover:bg-secondary hover:text-ternary"
            onClick={() => navigate('creer')}
          >
            <BsPlusLg />
          </button>
        </>
      ) : null}
      <Display condition={isFetching}>
        <Loader />
      </Display>
      <Display condition={isError}>Une erreur est survenue. Veuillez réessayer.</Display>
    </div>
  );
};
