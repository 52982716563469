import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store/store';

export type UpdateEventCommand = {
  eventId: string;
  place?: string;
  description?: string | null;
};

export const updateEvent = createAsyncThunk<void, UpdateEventCommand, ThunkConfig>(
  'events/updateEvent',
  (command, { extra: { eventApi } }) => eventApi!.updateEvent(command)
);
