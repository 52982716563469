import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { DetailedEvent } from '../../../../core/models/detailed-event';
import { Display } from '../../components/Display';
import { Error } from '../../components/Error';
import { Loader } from '../../components/Loader';
import { useDetailedEvent } from '../event/useDetailedEvent';
import { useToastStatus } from '../../../hooks/use-toast-status';
import { eventUpdateStatusSelector } from '../../../../core/store/selectors/event-update-status.selector';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../core/store/store';
import { updateEvent } from '../../../../core/usecases/update-event/update-event';
import { resetEventUpdateState } from '../../../../core/store/event-update.state';

export const EditEvent: FC = () => {
  const { id } = useParams<'id'>();
  const result = useDetailedEvent(id!);

  if (result.isFetching) {
    return <Loader />;
  }

  if (result.isError || !result.data) {
    return <Error />;
  }

  return <EditEventContent event={result.data.event} />;
};

export type EditEventFormValues = {
  place: string;
  description: string;
};

export const EditEventContent: FC<{ event: DetailedEvent }> = ({ event }) => {
  const { handleSubmit, register } = useForm<EditEventFormValues>({
    defaultValues: { description: event.description, place: event.place }
  });
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading } = useSelector(eventUpdateStatusSelector);

  const goBack = () => navigate('..', { relative: 'path' });

  function onSubmit(data: EditEventFormValues) {
    const place = data.place;
    const description = data.description ?? null;
    dispatch(updateEvent({ eventId: event.id, place, description }));
  }

  useToastStatus(eventUpdateStatusSelector, {
    success: `L'atelier a bien été mis à jour `,
    onSuccess: () => goBack()
  });

  useEffect(() => () => {
    dispatch(resetEventUpdateState());
  });

  return (
    <div className="w-full p-4">
      <h1 className="text-4xl underline mb-10" style={{ textDecorationColor: event.color }}>
        {event.title}
      </h1>

      <form className="v-stack gap-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="h-stack gap-2">
          <label className="text-xl">Lieu :</label>
          <input {...register('place')} required className="input" placeholder="Lieu" type="text" />
        </div>

        <div className="v-stack gap-2">
          <label className="text-xl">A propos</label>
          <textarea {...register('description')} className="input p-3" rows={6}></textarea>
        </div>

        <div className="h-stack gap-3">
          <Display condition={isLoading}>
            <Loader />
          </Display>
          <Display condition={!isLoading}>
            <button className="btn btn-secondary text-xl px-8 py-2 leading-none" type="button" onClick={goBack}>
              Annuler
            </button>
          </Display>
          <Display condition={!isLoading}>
            <button className="btn text-xl px-8 py-2 leading-none" type="submit">
              Mettre à jour
            </button>
          </Display>
        </div>
      </form>
    </div>
  );
};
