export type ValidEnvironments = 'production' | 'development';

const validEnvironments: Set<ValidEnvironments> = new Set(['production', 'development'] as const);

export function getEnvironment(): ValidEnvironments {
  const env = process.env.NODE_ENV;

  if (!checkEnvironmentIsValid(env)) {
    const formattedEnvironments = Array.from(validEnvironments)
      .map(e => `'${e}'`)
      .join(', ');
    throw new Error(
      `Environment should be one of those: ${formattedEnvironments}. Current value: '${process.env.NODE_ENV}'`
    );
  }

  return env;
}

function checkEnvironmentIsValid(env: unknown): env is ValidEnvironments {
  return !!env && validEnvironments.has(env as ValidEnvironments);
}

export function isProd() {
  return getEnvironment() === 'production';
}

export function isDev() {
  return getEnvironment() === 'development';
}
