import { getUnixTime, isEqual } from 'date-fns';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { months } from '../../../../core/utils/months';
import { useIsDesktop } from '../../../hooks/useIsDesktop';
import { Display } from '../../components/Display';
import { Select } from '../../components/Select';
import { CalendarContext } from './CalendarContext';
import { useListenEventHook } from './useListenEventHook';

type ViewMode = 'week' | 'month' | 'list';
const viewModes: ViewMode[] = ['week', 'month', 'list'];

interface Props {
  onEventClick: (eventId: string) => void;
}

const getDateFromQueryParameters = (searchParams: URLSearchParams): Date => {
  const timestamp = searchParams.get('t');
  const timestampAsNumber = Number.parseInt(timestamp ?? '');
  if (!timestampAsNumber) {
    return new Date();
  }
  return new Date(timestampAsNumber * 1000);
};

const getViewModeFromQueryParameters = (searchParams: URLSearchParams, defaultViewMode: ViewMode): ViewMode => {
  const viewMode = searchParams.get('v');
  if (!viewMode) {
    return defaultViewMode;
  }
  return viewModes.find(value => value === viewMode) ?? defaultViewMode;
};

export const Controls: FC<Props> = ({ onEventClick }) => {
  const api = useContext(CalendarContext);
  const [searchParams, setSearchParams] = useSearchParams(new URLSearchParams());
  const [date, setDate] = useState<Date>(getDateFromQueryParameters(searchParams));
  const isDesktop = useIsDesktop();
  const [viewMode, setViewMode] = useState<ViewMode>(
    getViewModeFromQueryParameters(searchParams, isDesktop ? 'week' : 'list')
  );
  const formattedDate = useMemo(() => {
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${year}`;
  }, [date]);

  useListenEventHook('datesSet', () => setDate(api.getDate()));
  useListenEventHook('eventClick', event => onEventClick(event.event.id));

  useEffect(() => {
    const viewModes: Record<ViewMode, string> = {
      week: 'timeGridWeek',
      month: 'dayGridMonth',
      list: 'listMonth'
    };
    api.changeView(viewModes[viewMode]);
  }, [api, viewMode]);

  useEffect(() => {
    setSearchParams(previousSearchParams => {
      previousSearchParams.set('v', viewMode);
      return previousSearchParams;
    });
  }, [viewMode, setSearchParams]);

  useEffect(() => {
    const timestamp = getUnixTime(date).toString();
    const timestampInQueryParams = searchParams.get('t');
    if (timestamp !== timestampInQueryParams) {
      setSearchParams(previousSearchParams => {
        previousSearchParams.set('t', timestamp);
        return previousSearchParams;
      });
    }
  }, [date, searchParams, setSearchParams]);

  useEffect(() => {
    if (!isEqual(api.getDate(), date)) {
      api.gotoDate(date);
    }
  }, [api, date]);

  return (
    <div className="w-full mb-3 h-stack justify-between">
      <div className="text-2xl">{formattedDate}</div>

      <div className="h-stack gap-1">
        <Display condition={isDesktop}>
          <button className="btn text-lg" onClick={() => api.today()}>
            Aujourd'hui
          </button>
        </Display>
        <Select value={viewMode} options={selectViewModeOptions} onChange={v => setViewMode(v as ViewMode)} />
        <button className="btn text-xl" onClick={() => api.prev()}>
          {'<'}
        </button>
        <button className="btn text-xl" onClick={() => api.next()}>
          {'>'}
        </button>
      </div>
    </div>
  );
};

const selectViewModeOptions = [
  { label: 'Semaine', value: 'week' },
  { label: 'Mois', value: 'month' },
  { label: 'Liste', value: 'list' }
];
