import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store/store';

export type CreateAccountCommand = {
  firstName: string;
  lastName: string;
  email: string;
  availableCategoryIds: string[];
};

export const createAccount = createAsyncThunk<void, CreateAccountCommand, ThunkConfig>(
  'users/createAccount',
  (command, { extra: { userApi } }) => userApi!.createAccount(command)
);
