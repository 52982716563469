import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { resetPasswordResetState } from '../../../../../core/store/password-reset.state';
import { passwordResetSelector } from '../../../../../core/store/selectors/password-reset.selector';
import { AppDispatch } from '../../../../../core/store/store';
import { resetPassword } from '../../../../../core/usecases/reset-password/reset-password';
import { useToastStatus } from '../../../../hooks/use-toast-status';

export const useResetPassword = () => {
  const token = useLoaderData() as string;
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  useToastStatus(passwordResetSelector, {
    success: 'Votre mot de passe a été modifié avec succès',
    error: 'Le lien de réinitialisation a expiré',
    finally: () => navigate('/login'),
    toastOptions: {
      autoClose: false
    }
  });

  useEffect(() => {
    return () => {
      dispatch(resetPasswordResetState());
    };
  }, [dispatch]);

  return {
    resetPassword: (password: string) => dispatch(resetPassword({ password, token }))
  };
};
