import { useMemo } from 'react';
import { useFetchCategories } from '../queries/categories/useFetchCategories';
import { Category } from '../../models/category';

export const useCategories = () => {
  const categoriesArray = useFetchCategories();

  const options = useMemo(() => {
    if (!categoriesArray.data) {
      return [];
    }
    return categoriesArray.data.map(category => ({
      label: category.title,
      value: category.id
    }));
  }, [categoriesArray]);

  const categoriesEntities = useMemo(() => {
    if (!categoriesArray.data) {
      return {};
    }
    return categoriesArray.data.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {} as Record<string, Category>);
  }, [categoriesArray]);

  return { options, categories: categoriesEntities };
};
