import { KyInstance } from 'ky/distribution/types/ky';
import { ValidEnvironments, getEnvironment } from '../utils/environment';
import { TokenManager } from '../token/token-manager';
import ky from 'ky';

export class HttpClient {
  private static readonly urls: Record<ValidEnvironments, string> = {
    development: 'http://localhost:4200',
    production: 'https://api.agenda.a-petits-pas.org'
  };

  private static onUnauthorizedRequestSubscribers: OnUnauthorizedRequestSubscriber[] = [];

  private static _instance: KyInstance = ky.create({
    prefixUrl: this.urls[getEnvironment()],
    hooks: {
      afterResponse: [
        (_request, _options, response) => {
          if (response.status === 401) {
            this.onUnauthorizedRequestSubscribers.forEach(subscriber => subscriber(response));
          }
          return response;
        }
      ]
    }
  });

  public static onUnauthorizedRequest(subscriber: OnUnauthorizedRequestSubscriber) {
    this.onUnauthorizedRequestSubscribers.push(subscriber);
  }

  private static initialize = (() => {
    TokenManager.onTokenChange(token => {
      if (!token) {
        this._instance = this._instance.extend({ headers: { Authorization: undefined } });
      } else {
        this._instance = this._instance.extend({ headers: { Authorization: `Bearer ${token}` } });
      }
    });
  })();

  public static get instance() {
    return this._instance;
  }
}

type OnUnauthorizedRequestSubscriber = (response: Response) => void;
