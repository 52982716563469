import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { isConnectedSelector } from '../../../core/store/selectors/is-connected-selector';

interface Props {
  children: React.ReactNode;
}

export const NotAuthenticatedGuard: FC<Props> = ({ children }) => {
  const isConnected = useSelector(isConnectedSelector);

  if (isConnected) {
    return <Navigate to="/ateliers" replace />;
  }

  return <>{children}</>;
};
