import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetLoginState } from '../../../../../core/store/login.state';
import { AppDispatch } from '../../../../../core/store/store';
import { login } from '../../../../../core/usecases/login/login';

export const useLogin = () => {
  const dispatch = useDispatch<AppDispatch>();
  const client = useQueryClient();

  const onLoginSuccess = async () => {
    client.invalidateQueries(['coins']);
  };

  useEffect(() => {
    return () => {
      dispatch(resetLoginState());
    };
  }, [dispatch]);

  return async (credentials: { email: string; password: string }) => {
    const result = await dispatch(login(credentials));

    if (result.meta.requestStatus === 'fulfilled') {
      await onLoginSuccess();
    }
  };
};
