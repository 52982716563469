const colors = require('tailwindcss/colors');

module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    extend: {
      colors: {
        primary: '#e51a19',
        secondary: '#330812',
        ternary: '#ffbf00',
        info: '#330812',
        danger: '#e51a19',
        warning: '#ffbf00',
        success: '#59CD90',
        'impro-coin': '#59CD90',
        'common-coin': '#CE84AD',
        link: colors.blue[400]
      }
    }
  },
  plugins: []
};
