import { createAction, createReducer } from '@reduxjs/toolkit';
import { AsyncStatus } from '../models/async-status';
import { unregisterFromEvent } from '../usecases/unregister-from-event/event-unregistration';

export type EventUnregistrationState = {
  status: AsyncStatus;
};

const initialState: EventUnregistrationState = {
  status: 'idle'
};

export const resetEventUnregistrationState = createAction('events/resetEventUnregistration');

export const eventUnregistrationReducer = createReducer(initialState, builder => {
  builder.addCase(resetEventUnregistrationState, () => initialState);
  builder.addCase(unregisterFromEvent.pending, state => ({ ...state, status: 'pending' }));
  builder.addCase(unregisterFromEvent.rejected, state => ({ ...state, status: 'failed' }));
  builder.addCase(unregisterFromEvent.fulfilled, state => ({ ...state, status: 'succeeded' }));
});
