import { EventSourceInput } from '@fullcalendar/react';
import { useFetchEvents } from '../../../../core/usecases/queries/events/useFetchEvents';

export const useFullCalendarEvents = () => {
  return useFetchEvents<EventSourceInput>(events => {
    return events.map(event => ({
      id: event.id,
      title: event.title,
      start: event.start,
      end: event.end,
      backgroundColor: event.isFull ? '#aaa' : event.color,
      borderColor: event.isFull ? '#aaa' : event.color
    }));
  });
};
