import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store/store';

export type UpdateUserCommand = {
  availableCategoryIds: string[];
  userId: string;
};

export const updateUser = createAsyncThunk<void, UpdateUserCommand, ThunkConfig>(
  'users/updateUser',
  (command, { extra: { userApi } }) => userApi!.updateUser(command)
);
