import { createAction, createReducer } from '@reduxjs/toolkit';
import { AsyncStatus } from '../models/async-status';
import { resetPassword } from '../usecases/reset-password/reset-password';

export type PasswordResetState = {
  status: AsyncStatus;
};

const initialState: PasswordResetState = {
  status: 'idle'
};

export const resetPasswordResetState = createAction('users/resetPasswordResetState');

export const passwordResetStateReducer = createReducer(initialState, builder => {
  builder.addCase(resetPasswordResetState, () => initialState);
  builder.addCase(resetPassword.pending, state => ({ ...state, status: 'pending' }));
  builder.addCase(resetPassword.rejected, state => ({ ...state, status: 'failed' }));
  builder.addCase(resetPassword.fulfilled, state => ({ ...state, status: 'succeeded' }));
});
