import { Dialog, Transition } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { FC, Fragment, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryType } from '../../../core/models/category-type';
import { resetEventRegistrationState } from '../../../core/store/event-registration.state';
import { eventRegistrationStatusSelector } from '../../../core/store/selectors/event-registration-status.selector';
import { AppDispatch } from '../../../core/store/store';
import { useCoinsAmounts } from '../../../core/usecases/queries/coins/useCoinsAmounts';
import { registerToEvent } from '../../../core/usecases/register-to-event/event-registration';
import { useToastStatus } from '../../hooks/use-toast-status';
import { CommonCoin, ImproCoin } from './Coin';
import { Display } from './Display';
import { Loader } from './Loader';
import { NavLink } from 'react-router-dom';

interface Props {
  show: boolean;
  close: () => void;
  categoryType: CategoryType;
  date: string;
  title: string;
  eventId: string;
}

export const EventRegistrationModal: FC<Props> = ({ show, close, categoryType, date, title, eventId }) => {
  const { data: coins } = useCoinsAmounts();
  const client = useQueryClient();
  const { isLoading, isIdle } = useSelector(eventRegistrationStatusSelector);
  const dispatch = useDispatch<AppDispatch>();

  const closeDialogByClickingOutside = () => (isLoading ? close() : undefined);

  useToastStatus(eventRegistrationStatusSelector, {
    success: 'Votre êtes maintenant inscrit à cet atelier',
    onSuccess: async () => {
      await client.invalidateQueries(['event']);
      await client.invalidateQueries(['coins']);
    },
    finally: () => {
      dispatch(resetEventRegistrationState());
      close();
    }
  });

  const canRegister = useMemo(() => {
    if (!coins) {
      return false;
    }
    return (categoryType === 'impro' && coins.impro >= 1) || (categoryType === 'common' && coins.common >= 1);
  }, [coins, categoryType]);

  const coinName = useMemo(() => {
    const names: Record<CategoryType, string> = { common: 'autre atelier', impro: 'impro' };
    return names[categoryType];
  }, [categoryType]);

  const register = () => dispatch(registerToEvent({ eventId }));

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeDialogByClickingOutside}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="div" className="h-stack gap-2 justify-between">
                    <h3 className="text-2xl font-medium leading-6">Inscription - {title}</h3>
                    <div className="h-stack items-center gap-1">
                      {categoryType === 'impro' ? <ImproCoin /> : <CommonCoin />}
                      <span className="text-xl leading-none">1</span>
                    </div>
                  </Dialog.Title>
                  <div className="mt-2 text-md">
                    <p className="text-gray-500">{date}</p>
                    <br />
                    <Display condition={canRegister}>
                      <p>
                        <span className="text-danger">Attention !</span> <br />
                        En cas d'annulation, votre crédit sera remboursé uniquement si vous annulez au moins{' '}
                        <span className="font-bold underline">48h</span> avant le début de l'atelier.
                      </p>
                      <br />
                      <p>En confirmant, vous utiliserez un crédit "{coinName}" et vous serez inscrit à cet atelier.</p>
                    </Display>
                    <Display condition={!canRegister}>
                      <p>
                        Vous n'avez plus de crédit {coinName}. Accédez à la page{' '}
                        <NavLink className="underline text-link" to="/mes-credits">
                          crédits
                        </NavLink>{' '}
                        pour en obtenir.
                      </p>
                    </Display>
                  </div>

                  <Display condition={isIdle}>
                    <div className="mt-4 h-stack gap-2 justify-end">
                      <button type="button" className="btn-secondary !py-1 px-2 text-lg" onClick={close}>
                        Retour
                      </button>
                      <Display condition={canRegister}>
                        <button type="button" className="btn !py-1 px-2 text-lg" onClick={register}>
                          Confirmer
                        </button>
                      </Display>
                    </div>
                  </Display>
                  <Display condition={isLoading}>
                    <div className="mt-4 h-stack justify-center">
                      <Loader />
                    </div>
                  </Display>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
